<template>
  <div class="promotion">
    <GeneralDialog :visible.sync="visible" visibleFooter needFullscreen hideChat @close="disableAgreeTncVisible(false)">
      <div class="top">
        <slot></slot>
      </div>

      <div class="tnc">
        <el-form :model="form" :rules="rule" :show-message="false" ref="Form">
          <el-form-item prop="checked">
            <el-checkbox v-model="form.checked" data-testid="tncConfirm">
              <div class="tncLabel">{{ $t('promotion.cryptoCashback.dialog.agreeTnc') }}</div>
            </el-checkbox>
          </el-form-item>
        </el-form>
      </div>

      <div class="form-box">
        <div class="btn-box">
          <el-button
            class="btn-blue"
            :class="[onButtonTextStyle]"
            @click="agreeTncAndSubmit"
            :disabled="!form.checked"
            data-testid="agreeAction"
          >
            {{ $t('common.button.confirm') }}
          </el-button>
        </div>
      </div>
    </GeneralDialog>
  </div>
</template>

<script>
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import { checkAgreedTNC } from '@/util/validation';
import { apiOptInWithAccount } from '@/resource';

export default {
  name: 'AgreeTncDialog',
  props: {
    agreeTncVisible: {
      type: Boolean,
      default: false
    },
    headerText: {
      type: String,
      default: ''
    },
    accountData: {
      type: Object,
      default: () => ({})
    },
    buttonTextStyle: {
      type: String,
      default: 'capitalize'
    }
  },
  components: { GeneralDialog },
  data() {
    return {
      visible: false,
      form: {
        checked: false
      },
      rule: {
        checked: [{ validator: checkAgreedTNC, trigger: 'change' }]
      }
    };
  },
  watch: {
    agreeTncVisible(bool) {
      this.form.checked = false;
      this.visible = bool;
    },
    visible(bool) {
      this.disableAgreeTncVisible(bool);
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    onButtonTextStyle() {
      return this.buttonTextStyle;
    }
  },
  methods: {
    disableAgreeTncVisible(bool) {
      this.$nextTick(() => {
        this.$emit('update:agreeTncVisible', bool);
      });
    },
    async agreeTncAndSubmit() {
      const { campaignID, value } = this.accountData;
      const queryData = {
        campaignId: campaignID,
        accountMt4Dto: { ...value }
      };

      try {
        const { data } = await apiOptInWithAccount(queryData);
        const isError = data.data === false;
        if (isError) {
          this.failAndCloseTnc();
          return;
        }

        this.disableAgreeTncVisible(false);
        this.$emit('activateSuccess');
      } catch (e) {
        this.failAndCloseTnc();
        return;
      }
    },
    failAndCloseTnc() {
      this.disableAgreeTncVisible(false);

      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/agreeTnc.scss';
.text-uppercase {
  text-transform: uppercase;
}

/deep/ .el-dialog__body .top li {
  padding-bottom: 20px !important;
}

.common-dialog-wrapper /deep/ {
  .dialog-body {
    padding: 0 0 45px 0;
  }
}

/deep/ .el-button {
  background-color: $future-blue;
  color: white;
  &.is-disabled {
    background-color: $future-blue;
    color: white;
  }
}
</style>
