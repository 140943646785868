<template>
  <div class="cb-dialog">
    <GeneralDialog
      :visible.sync="open"
      visibleHeader
      hideChat
      :class="{ 'context-with-btn': dialogHeightAuto }"
      :closeOnClickModal="closeOnClickModal"
      @close="closCbDialog"
    >
      <div class="cb-dialog-header">
        <div class="dialog_header_icon">
          <img
            :src="
              isResSuccess ? require(`@/assets/images/dialog/smile.png`) : require(`@/assets/images/dialog/sad.png`)
            "
            alt="emoji"
          />
        </div>

        <!-- close button -->
        <em class="el-icon-close close-image" @click="closCbDialog"></em>
      </div>
      <div class="cb-dialog-body">
        <slot name="content" />
      </div>
    </GeneralDialog>
  </div>
</template>

<script>
import GeneralDialog from '@/components/dialog/general/Dialog.vue';

export default {
  name: 'ConditionDialog',
  components: {
    GeneralDialog
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    dialogHeightAuto: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    },
    closeOnClickModal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      open: false,
      isDialogHeightAuto: false,
      isResSuccess: false
    };
  },
  watch: {
    visible(val) {
      this.open = val;
    },
    open(bool) {
      this.handleClose(bool);
    },
    dialogHeightAuto: {
      immediate: true,
      handler(newValue) {
        this.dialogHeightAuto = newValue;
      }
    },
    isSuccess: {
      immediate: true,
      handler(newValue) {
        this.isResSuccess = newValue;
      }
    }
  },
  methods: {
    handleClose(bool) {
      this.$nextTick(() => {
        this.$emit('update:visible', bool);
      });
    },
    closCbDialog() {
      this.$emit('closCbDialog');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/dialog/conditionDialog.scss';

.cb-dialog-body /deep/ {
  .text-congratuate {
    font-weight: 900;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .text-result {
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .submit-btn {
    margin-bottom: 16px;
    min-width: 180px;
  }
}

.common-dialog-wrapper /deep/ {
  .dialog-body {
    padding: 0 0 45px 0;
    height: 256px;
    max-height: 310px;
  }
  .el-dialog__body p {
    padding: 0 4% !important;
  }
}

.common-dialog-wrapper.context-with-btn /deep/ {
  .dialog-body {
    height: auto;
    max-height: none;
  }
}

@media (min-width: 550px) {
  .common-dialog-wrapper /deep/ {
    .dialog-body {
      padding: 0 35px 45px 30px;
    }
    .el-dialog__body p {
      padding: 0 20% !important;
    }
  }
}
</style>
