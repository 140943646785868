<template>
  <div class="l-select" :value="$attrs.value" @click.capture="visible = true" :class="{ active: visible }">
    <el-form-item :label="label" :prop="name">
      <el-select
        v-on="listeners"
        v-bind="$attrs"
        :id="name"
        filterable
        :class="{ showPlaceholder: showPlaceholder }"
        :data-testid="testId || name"
        :popper-class="onPopperClass"
        :placeholder="placeholder"
      >
        <slot></slot>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'SelectTemplate',
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    testId: {
      type: String,
      default: ''
    },
    popperClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      showPlaceholder: false
    };
  },
  computed: {
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: event => this.$emit('input', event),
        focus: event => (this.showPlaceholder = true)
      });
    },
    onPopperClass() {
      return `${this.popperClass} dropdown-v2`;
    }
  }
};
</script>

<style lang="scss" scoped>
.l-select {
  position: relative;
  position: sticky;
  z-index: 101;

  input {
    position: sticky;
    z-index: 101;
  }

  &.active,
  &[value]:not([value='']) {
    /deep/ label {
      font-size: 14px;
      bottom: 45px;
    }
  }
}
</style>
