<template>
  <div class="table-container">
    <!-- table -->
    <el-row :gutter="20">
      <el-col :span="24">
        <div>
          <el-table :data="displayData" class="head-gutter">
              <el-table-column v-for="key in columnKey" :key="key" align="center" :label="$t(`promotion.firstDepositCashback.field.${key}`)" min-width="200">
                <template class="text-center" slot-scope="scope">
                  <span v-if="key === 'cashBackValue'">$ {{ scope.row[key] }} {{ account }}</span>
                  <span v-else>{{ scope.row[key] }}</span>
                </template>
              </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';

export default {
  name: 'DepositHistory',
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    account: {
      type: String,
      default: ''
    }
  },
  components: { Pagination },
  computed: {
    displayData() {
      return [...this.tableData];
    },
    columnKey(){
      if (this.tableData.length === 0) return [];
      return Object.keys(this.tableData[0]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/blueHeadTable.scss';
.table-title.with-account {
  margin-bottom: 16px;
}
.account-context {
  margin-top: 4px;
  margin-bottom: 16px;
}
</style>
