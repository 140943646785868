<template>
  <section id="firstDepositCashback" :class="campaignName">
    <!-- banner -->
    <div class="banner-container">
      <div class="new-banner-image">
        <BannerItemV2 v-bind="banner" :lang="imgLang" />
      </div>
    </div>

    <!-- main -->
    <div class="content-box">
      <div class="inner">
        <FundAccount
          :optIn="optIn"
          :campaignID="campaignID"
          :campaignName="campaignName"
          @activeOptIn="onActivate"
          @deactivateAndQueryData="onDeactivate"
          @getOptStatus="getOptStatus"
        >
        </FundAccount>
      </div>
    </div>
  </section>
</template>

<script>
import BannerItemV2 from '@/components/banner/item/v2/BannerItem.vue';
import FundAccount from '@/components/promotion/firstDepositCashback/FundAccount.vue';
import DepositHistory from '@/components/promotion/firstDepositCashback/DepositHistory.vue';
import { PROMOTION_TYPE } from '@/constants/promotion';
import { apiCampaignOptStatus } from '@/resource';
import { isSuccess } from '@/util/restApi';
import { mapGetters } from 'vuex';

export default {
  name: 'firstDepositCashback',
  components: {
    BannerItemV2,
    FundAccount,
    DepositHistory,
  },
  data() {
    return {
      optIn: false, // once user opt-in, the api will return true forever,
      depositHistoryData: [],
      participateAccount: '',
      banner: null,
    };
  },
  computed: {
    ...mapGetters('promotion/firstDepositCashback', ['campaignID']),
    imgLang() {
      return this.$store.state.common.lang;
    },
    campaignName() {
      return this.$route.name;
    }
  },
  mounted() {
    this.init();
    this.getBanner();
  },
  methods: {
    init() {
      this.getOptStatus();
    },
    getBanner() {
      const campaignID = this.campaignID;
      this.banner = this.$config
        .banner(this.regulator)
        .filter(el => el.promotionId === campaignID)[0];
    },
    getOptStatus() {
      if (!this.campaignID) return;

      apiCampaignOptStatus(this.campaignID)
        .then(res => {
          const { data: result } = res;
          if (isSuccess(result)) {
            this.optIn = result.data;
          }
        })
        .catch(e => console.log(e));
    },
    onActivate() {
      this.init();
    },
    onDeactivate(optStatus) {
      if (optStatus === 'failOptout') {
        this.init();
        this.optIn = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/firstDepositCashback.scss';
</style>
