import { render, staticRenderFns } from "./DepositHistory.vue?vue&type=template&id=474e7d5a&scoped=true&"
import script from "./DepositHistory.vue?vue&type=script&lang=js&"
export * from "./DepositHistory.vue?vue&type=script&lang=js&"
import style0 from "./DepositHistory.vue?vue&type=style&index=0&id=474e7d5a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474e7d5a",
  null
  
)

export default component.exports